const countriesByLocales = [
  {
    name: 'Worldwide',
    technicalName: 'Worldwide',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'worldwide',
    countryCode: 'worldwide',
    continent: 'Global',
    salesforceCountry: 'Group',
    campaignToken: 'G',
  },
  {
    name: 'France',
    technicalName: 'France',
    locale: 'French (France) (fr-FR)',
    localeCode: 'fr-FR',
    localeName: 'French',
    urlPrefix: 'france',
    countryCode: 'fr',
    continent: 'Europe',
    salesforceCountry: 'France',
    campaignToken: 'e',
  },
  {
    name: 'Belgium',
    technicalName: 'Belgium',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'be',
    countryCode: 'be',
    continent: 'Europe',
    salesforceCountry: 'Belgium',
    campaignToken: 'k',
  },
  {
    name: 'Spain',
    technicalName: 'Spain',
    locale: 'Spanish (Spain) (es-ES)',
    localeCode: 'es-ES',
    localeName: 'Spanish',
    urlPrefix: 'spain',
    countryCode: 'sp',
    continent: 'Europe',
    salesforceCountry: 'Spain',
    campaignToken: 'A',
  },
  {
    name: 'Brazil',
    technicalName: 'Brasil',
    locale: 'Portuguese (Portugal) (pt-BR)',
    localeCode: 'pt-BR',
    localeName: 'Portuguese',
    urlPrefix: 'br',
    countryCode: 'br',
    continent: 'America',
    salesforceCountry: 'Brazil',
    campaignToken: 'D',
  },
  {
    name: 'Latin America',
    technicalName: 'Latin America (Spanish)',
    locale: 'Spanish (Latin America) (es-419)',
    localeCode: 'es-419',
    localeName: 'Spanish',
    urlPrefix: 'latin-america',
    countryCode: 'latam',
    continent: 'America',
    salesforceCountry: 'Colombia',
    campaignToken: 'v',
  },
  {
    name: 'Mexico',
    technicalName: 'Mexico',
    locale: 'Spanish (Latin America) (es-419)',
    localeCode: 'es-419',
    localeName: 'Spanish',
    urlPrefix: 'mexico',
    countryCode: 'mx',
    continent: 'America',
    salesforceCountry: 'Colombia',
    campaignToken: 'v',
  },
  {
    name: 'Portugal',
    technicalName: 'Portugal',
    locale: 'Portuguese (Portugal) (pt-PT)',
    localeCode: 'pt-PT',
    localeName: 'Portuguese',
    urlPrefix: 'portugal',
    countryCode: 'pt',
    continent: 'Europe',
    salesforceCountry: 'Portugal',
    campaignToken: 'c',
    isMultiLang: true,
  },
  {
    name: 'Portugal',
    technicalName: 'Portugal (English)',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'portugal',
    countryCode: 'pt',
    continent: 'Europe',
    salesforceCountry: 'Portugal',
    campaignToken: 'c',
    isMultiLang: true,
  },
  {
    name: 'United Kingdom',
    technicalName: 'United Kingdom',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'united-kingdom',
    countryCode: 'uk',
    continent: 'Europe',
    salesforceCountry: 'United Kingdom',
    campaignToken: '1',
  },
  {
    name: 'United States',
    technicalName: 'United States',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'united-states',
    countryCode: 'us',
    continent: 'America',
    salesforceCountry: 'United States',
    campaignToken: 'u',
  },
  {
    name: 'Canada',
    technicalName: 'Canada (English)',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'canada',
    countryCode: 'ca',
    continent: 'America',
    salesforceCountry: 'Canada',
    campaignToken: 'r',
    isMultiLang: true,
  },
  {
    name: 'Canada',
    technicalName: 'Canada (French)',
    locale: 'French (Canada) (fr-CA)',
    localeCode: 'fr-CA',
    localeName: 'French',
    urlPrefix: 'canada',
    countryCode: 'ca',
    continent: 'America',
    salesforceCountry: 'Canada',
    campaignToken: 'b',
    isMultiLang: true,
  },
  {
    name: 'South Africa',
    technicalName: 'South Africa',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'south-africa',
    countryCode: 'za',
    continent: 'Africa',
    salesforceCountry: 'South Africa',
    campaignToken: 'B',
  },
  {
    name: 'Middle East & Africa',
    technicalName: 'Middle East & Africa (English)',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'middle-east-africa',
    countryCode: 'mea',
    continent: 'Africa',
    salesforceCountry: 'Tunisia',
    campaignToken: 'J',
    isMultiLang: true,
  },
  {
    name: 'Middle East & Africa',
    technicalName: 'Middle East & Africa (French)',
    locale: 'French (France) (fr-FR)',
    localeCode: 'fr-FR',
    localeName: 'French',
    urlPrefix: 'middle-east-africa',
    countryCode: 'mea',
    continent: 'Africa',
    salesforceCountry: 'Tunisia',
    campaignToken: 'Q',
    isMultiLang: true,
  },
  {
    name: 'China',
    technicalName: 'China (English)',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'china',
    countryCode: 'cn',
    continent: 'Asia',
    salesforceCountry: 'China',
    campaignToken: 'x',
  },
  {
    name: 'South East Asia',
    technicalName: 'South East Asia',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'south-east-asia',
    countryCode: 'sea',
    continent: 'Asia',
    salesforceCountry: 'Singapore',
    campaignToken: 'Y',
  },
  {
    name: 'Israël',
    technicalName: 'Israël',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'il',
    countryCode: 'il',
    continent: 'Europe',
    salesforceCountry: 'France',
    campaignToken: 'e',
  },
]

module.exports = {
  countriesByLocales,
}
